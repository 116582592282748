body {
  background-color: #fef3c7;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
}

header {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

input, button {
  font-size: 1rem;
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 20pt;
  }

  body {
    padding: 20px;
  }
}
